import React, { useEffect, useState } from 'react';
import "./PortfolioPage.css";
import avatar from '../assets/images/my-avatar.png';
import githubLogo from '../assets/images/icon-logo-github.png';
import linkedinLogo from '../assets/images/icon-logo-linkedin.png';
import mail from '../assets/images/icon-email-50.png';


const PortFolioTopImage = () => {
    return (
        <div>
            <img id="my-avatar" src={avatar} alt="Jeff as a Cartoon" />
        </div>
    );
};


const Contacts = ({ img, type, contactInfo }) => {
    return (
        <section className="contact-info">
            <img src={img} alt={type} />
            <div className="contact-info-text">
                <h4>{type}</h4>
                <p>{contactInfo}</p>
            </div>
        </section>
    )
}

const PortfolioHeader = () => {
    return (
        <section id="portfolio-page-header">
            <PortFolioTopImage />
            <h3>
                Jeffrey Bradley
            </h3>
            <ul>
                <li>
                    <a href="https://github.com/JeffreyBradley772">
                        <img src={githubLogo} alt="My Github Profile" />
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/in/jeffreybradley772/">
                        <img src={linkedinLogo} alt="My LinkedIn Profile" />
                    </a>
                </li>
                <hr />
            </ul>

            <Contacts img={mail} type="Email" contactInfo="JeffreyBradley772@gmail.com" />
        </section>
    );
};



// PROJECTS

const ProjectsConatiner = () => {

    const [selectedOption, setSelectedOption] = useState('Portfolio');

    return (
        <section id="projects-wrapper">
            <CornerOptions selectedOption={selectedOption} setSelectedOption={setSelectedOption} />

            <div className="content">
                {selectedOption === 'Experience' && <ExpiericeContent />}
                {selectedOption === 'Portfolio' && <PortfolioContent />}
                {selectedOption === 'About Me' && <AboutContent />}
                {selectedOption === 'Resume' && <ResumeContent />}
            </div>
        </section>
    )
};

const CornerOptions = ({ selectedOption, setSelectedOption }) => {
    return (
        <section id="corner-options">
            <ul >
                <li className={selectedOption === 'Experience' ? 'active' : ''} onClick={() => { setSelectedOption('Experience') }}>
                    <button>Experience</button>
                </li>
                <li className={selectedOption === 'About Me' ? 'active' : ''} onClick={() => { setSelectedOption('About Me') }}>
                    <button>About me</button>
                </li>
                <li className={selectedOption === 'Portfolio' ? 'active' : ''} onClick={() => { setSelectedOption('Portfolio') }}>
                    <button>Portfolio</button>
                </li>
                <li className={selectedOption === 'Resume' ? 'active' : ''} onClick={() => { setSelectedOption('Resume') }}>
                    <button>Resume</button>
                </li>
            </ul>
        </section>
    );
}

const ExpiericeContent = () => {
    return (
        <div>
            <h4>Experience</h4>
            <p>Here is some of my experience</p>
        </div>
    );
}

const PortfolioContent = () => {
    const projects = ["Android", "Web", "API Gateway", "Infrastructure"]

    const [selectedProject, setSelectedProject] = useState(projects[0])

    return (
        <div>
            <h4>Portfolio</h4>
            <ProjectOptions projects={projects} selectedProject={selectedProject} setSelectedProject={setSelectedProject} />
            {selectedProject === "Android" && < AndroidPojects />}
        </div>
    );
}

const AboutContent = () => {
    return (
        <div>
            <h4>About me</h4>
            <p>Here is some information about me</p>
        </div>
    );
}

const ResumeContent = () => {
    return (
        <div>
            <h4>Resume</h4>
            <p>Here is my resume</p>
        </div>
    );
}

const Projects = () => {
    return (
        <div>
            <h4>Projects</h4>
            <p>Here are some of the projects I have worked on</p>
        </div>
    );
}

const ProjectOptions = ({ projects, selectedProject, setSelectedProject }) => {
    return (
        <ul>
            {projects.map((project, index) => (
                <li className='project-option' key={index}>
                    <nav
                        onClick={() => setSelectedProject(project)}
                        className={`${selectedProject === project ? 'selected' : ''}`}
                    >
                        {project}
                    </nav>
                </li>
            ))}
        </ul >
    )
}

const E2eProject = () => {
    return (
        <div className='project-wrapper'>
            <h4>E2E Project</h4>
            <p>Here is the E2E project</p>
        </div>
    );
}

const AndroidPojects = () => {
    return (
        <section className='project-grouping'>
            <E2eProject />
        </section>
    )
}

const ApiButton = () => {
    const baseURL = 'http://api.marketstack.com/v1/eod/';
    const apiAccessKey = 'd00076aa31f8e4c605ed00bfed06152a';
    const symbol = 'AAPL';
    const date = '2021-01-01';
    const url = `${baseURL}?access_key=${apiAccessKey}&symbols=${symbol}`;
    const url2 =
        "http://api.marketstack.com/v1/eod?access_key=d00076aa31f8e4c605ed00bfed06152a&symbols=AAPL&date_from=2024-02-26&date_to=2024-03-07";
    const options = {
        method: "GET",
    };

    console.log(url);

    const clicker = async () => {
        try {
            const response = await fetch(url2, options);
            const result = await response.text();
            console.log(result);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <button onClick={clicker}>Click me</button>
    );

}


function PortfolioPage() {
    return (
        <React.Fragment>
            <div className="portfolio-wrapper">
                <PortfolioHeader />
                <ProjectsConatiner />
            </div>
        </React.Fragment>
    );
}

export default PortfolioPage;