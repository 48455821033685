import React from "react";
import "./CoolThings.css"

function ThingsIThinkAreCool() {

    const CoolThings = () => {
        return (
            <React.Fragment>
                <a href="https://www.awwwards.com/">Website of the Day</a>
                <a href="https://explore.skillbuilder.aws/learn/course/external/view/elearning/17373/aws-escape-room-exam-prep-for-aws-certified-cloud-practitioner-clf-c02?sc_channel=sm&sc_campaign=AWS%20Training%20and%20Certification&sc_publisher=LINKEDIN&sc_geo=GLOBAL&sc_outcome=awareness&linkId=409025757">AWS Escape Room for Cloud Engineers</a>
                <a href="https://thechipletter.substack.com/p/demystifying-gpu-compute-architectures">Great GPU Architecture Explanation</a>
                <a href="https://jacobbartlett.substack.com/p/through-the-ages-apple-cpu-architecture">Apple's CPU Timeline</a>
                <a href="https://openai.com/index/sora/">OpenAI's Sora (Insanity)</a>
                <a href="https://typeset.io/">AI Powered Research Paper Search Engine</a>
                <a href="https://tldr.tech/">TLDR - Daily Tech Newsletter</a>
                <a href="https://usa.kaspersky.com/resource-center/definitions/what-is-cryptojacking">Cryptojacking</a>
                <a href="https://www.roblox.com/games/10217794885/Roblox-Career-Center">Roblox's Metaverse Style Career Center</a>
            </React.Fragment>

        );
    }

    return (
        <section className = "interests">
            <h2>Things I Think Are Cool</h2>
            <div className="link-grid">
                <CoolThings />
            </div>
        </section>
    );
}

export default ThingsIThinkAreCool