import React, { useEffect, useState } from 'react';
import './resume.css';  // Ensure this import is correct and CSS is applied

const HtmlRenderer = () => {
    return (
        <div>
            <a className="btn download-link" href="Jeffrey Bradley - Resume.pdf">
                Download PDF
            </a>
            {/* //  INTRO  // */}
            <header>
                <div className="content-wrapper">
                    <h1>Jeffrey Bradley</h1>
                    <p>
                        <a href="https://linkedin.com/in/jeffreybradley772">LinkedIn</a> |{" "}
                        <a href="https://github.com/JeffreyBradley772">GitHub</a>
                    </p>
                </div>
            </header>
            <main className="content-wrapper">
                {/* //  PROFILE  // */}
                <section className="resume-section">
                    {/* Start content edits below this line. */}
                    <h2>Profile</h2>
                    <p>
                        Software engineer who enjoys building, tooling, and learning. I have
                        experience in full-stack development, android app development,
                        embeddeded/MCU programming, a bit of electrical engieneering, but I love
                        working in a variety of tech stacks.
                    </p>
                    <p>
                        I also have ample experience working 1-on-1 with customers to create,
                        improve, and sell products and features that meet their needs. For
                        example, working at Higher Ground LLC, I've spoken directly with
                        individuals part of small teams in the US Military to develop a variety
                        of web applications and android app features that allow them to seemless
                        integrate with a hand held, MCU powered, satellite antenna for their
                        telecom needs.
                    </p>
                    <p>
                        I am used to wearing many hats and filling many roles from grinding out
                        code to assisting in sales and marketing. Naturally, working at startups
                        has been the majority of my career. I've come to enjoy the endless
                        problem solving.
                    </p>
                    <a href="/">Back to homepage</a>
                    {/* End content edits here. */}
                </section>
                {/* //  WORK EXPERIENCE  // */}
                <section className="resume-section">
                    <h2>Professional Experience</h2>
                    <section className="work-item">
                        <div className="work-details">
                            <h3>Full Stack Software Engineer</h3>
                            <p className="company-info">
                                <a href="https://www.higherground.earth/" target="_blank">
                                    Higher Ground
                                </a>{" "}
                                (Palo Alto, CA)
                            </p>
                            <p className="date-range">June 2021 – Present</p>
                        </div>
                        <div className="work-summary">
                            <ul>
                                <li>
                                    Leading a team of software engineers on a fast-paced project to
                                    integrate end-to-end encryption into a text messaging system over
                                    satellite, updating both the Android application and backend
                                    server codebase, and optimizing the database for secure key
                                    storage and transmission.
                                </li>
                                <li>
                                    Modernized the database management for satellite channel and
                                    customer information by designing a Django-based admin page with
                                    an updated schema that reduced database size by over 60%.{" "}
                                </li>
                                <li>
                                    Deployed the admin page on an Azure VM instance using Docker
                                    Compose, significantly enhancing data security and efficiency by
                                    reducing database exporting from an hours long manual job to an
                                    automated practically instant task.
                                </li>
                                <li>
                                    Regularly identified and resolved bugs from QA in the messaging
                                    app, alongside implementing UI/UX enhancements to improve app
                                    performance using the latest Jetpack Compose framework.
                                </li>
                                <li>
                                    Enhanced our geofencing software in C++ to allow manual GPS input
                                    when GPS is spoofed or unavailable, using the JNI API to interact
                                    with the native code.
                                </li>
                                <li>
                                    Developed an image processing workflow, encompassing image
                                    capture, crop, and compression functionalities, along with the
                                    complete integration of UI/UX development into messaging, allowing
                                    transmission over a secure satellite network.
                                </li>
                                <li>
                                    Streamlined the customer onboarding and application updating by
                                    introducing an encrypted text and QR code scanning feature that
                                    facilitates offline database imports and enhanced security of
                                    sensitive data.
                                </li>
                                <li>
                                    Updated handheld satellite device firmware in C to enhance error
                                    logging and security, from controlling the power spectral density
                                    output to toggling the external LEDs for improved discreteness.
                                </li>
                                <li>
                                    Pitched and demonstrated the application to government and
                                    military personnel in a sales engineering setting.
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section className="work-item">
                        <div className="work-details">
                            <h3>Electrical and Instrumentation Engineer</h3>
                            <p className="company-info">
                                <a href="https://attochron.com/" target="_blank">
                                    Attochron LLC
                                </a>{" "}
                                (Lexington, VA)
                            </p>
                            <p className="date-range">Jan 2020 – June 2021</p>
                        </div>
                        <div className="work-summary">
                            <ul>
                                <li>
                                    Programmed pipeline algorithms for data acquisition, processing,
                                    and visualization for individual sensor systems with Python
                                    Pandas, NumPy, and Matplotlib that reduced turn around for data
                                    understanding from a week to a day.
                                </li>
                                <li>
                                    Designed and maintained scripts for equipment hardware via serial
                                    library that automated controls allowing for less men in the field
                                    and remote operations.{" "}
                                </li>
                                <li>
                                    Represented the company at the 2020 USAF AFWERX Base of the Future
                                    Event, pitching the technology and answering technical questions.
                                </li>
                            </ul>
                        </div>
                    </section>
                </section>
                <section className="resume-section">
                    <h2>Technical Skills</h2>
                    <p>
                        Below are some of the technologies I have worked with professionally.
                    </p>
                    <section className="resume-section skills-section">
                        <div className="skills-category">
                            <h3>Programming Languages</h3>
                            <div className="programming-languages">
                                <img
                                    src="/images/logo-python.png"
                                    alt="Python logo"
                                    title="Python"
                                />
                                <img src="/images/logo-java.png" alt="Java logo" title="Java" />
                                <img
                                    src="/images/logo-kotlin.png"
                                    alt="Kotlin logo"
                                    title="Kotlin"
                                />
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/1200px-Unofficial_JavaScript_logo_2.svg.png"
                                    alt="JavaScript logo"
                                    title="JavaScript"
                                />
                                <img
                                    src="/images/logo-assembly.png"
                                    alt="Assembly logo"
                                    title="Assembly"
                                />
                                <img src="/images/logo-c.png" alt="C logo" title="C" />
                                <img src="/images/logo-c++.png" alt="C++ logo" title="C++" />
                            </div>
                        </div>
                        <div className="skills-category">
                            <h3>Web Development</h3>
                            <div className="web-development">
                                <img
                                    src="/images/django-logo.png"
                                    alt="Django logo"
                                    title="Django"
                                />
                                <img
                                    src="https://www.w3.org/html/logo/downloads/HTML5_Logo_512.png"
                                    alt="HTML5 logo"
                                    title="HTML5"
                                />
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1200px-CSS3_logo_and_wordmark.svg.png"
                                    alt="CSS3 logo"
                                    title="CSS3"
                                />
                                <img src="/images/logo-react.png" atl="React Logo" title="React" />
                                <img src="/images/logo-node.png" alt="Node Logo" title="Node" />
                            </div>
                        </div>
                        <div className="skills-category">
                            <h3>Mobile</h3>
                            <div className="mobile">
                                <img
                                    src="/images/logo-android.png"
                                    alt="Android logo"
                                    title="Android"
                                />
                            </div>
                        </div>
                        <div className="skills-category">
                            <h3>Data Science &amp; Machine Learning</h3>
                            <div className="data-science">
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/TensorFlowLogo.svg/2560px-TensorFlowLogo.svg.png"
                                    alt="TensorFlow logo"
                                    title="TensorFlow"
                                />
                                <img
                                    src="/images/logo-pandas.png "
                                    alt="Pandas logo"
                                    title="Pandas"
                                />
                                <img src="/images/logo-numpy.png" alt="NumPy logo" title="NumPy" />
                                <img
                                    src="/images/logo-scikit.png"
                                    alt="Scikit-learn logo"
                                    title="Scikit-learn"
                                />
                                <img
                                    src="/images/logo-jupyter.png"
                                    alt="Jupyter Notebook logo"
                                    title="Jupyter Notebook"
                                />
                            </div>
                        </div>
                        <div className="skills-category">
                            <h3>Database Management</h3>
                            <div className="database-management">
                                <img
                                    src="https://www.mysql.com/common/logos/logo-mysql-170x115.png"
                                    alt="MySQL logo"
                                    title="MySQL"
                                />
                                <img
                                    src="/images/logo-php.png"
                                    alt="phpMyAdmin logo"
                                    title="phpMyAdmin"
                                />
                                <img src="/images/logo-mariadb.png" alt="MariaDB" title="MariaDB" />
                            </div>
                        </div>
                        <div className="skills-category">
                            <h3>Cloud Services &amp; Deployment</h3>
                            <div className="cloud-computing">
                                <img src="/images/logo-azure.png" alt="Azure logo" title="Azure" />
                                <img
                                    src="/images/logo-docker.png"
                                    alt="Docker logo"
                                    title="Docker"
                                />
                                <img src="/images/logo-aws-ec2.png" alt="AWS logo" title="AWS" />
                                <img
                                    src="/images/logo-digital_ocean.png"
                                    alt="Digital Ocean"
                                    title="Digital Ocean"
                                />
                            </div>
                        </div>
                        <div className="skills-category">
                            <h3>Version Control &amp; CI/CD</h3>
                            <div className="version-control">
                                <img
                                    src="/images/icon-logo-github.png"
                                    alt="GitHub logo"
                                    title="GitHub"
                                />
                                <img
                                    src="/images/logo-gitlab.png"
                                    alt="GitLab logo"
                                    title="GitLab"
                                />
                                <img src="/images/logo-jenkins.png" alt="Jenkins" title="Jenkins" />
                            </div>
                        </div>
                        <div className="skills-category">
                            <h3>Hardware &amp; IoT</h3>
                            <div className="hardware-iot">
                                <img
                                    src="https://www.raspberrypi.com/app/uploads/2011/10/Raspi-PGB001.png"
                                    alt="Raspberry Pi logo"
                                    title="Raspberry Pi"
                                />
                                <img src="/images/logo-stm32.jpg" alt="STM32 logo" title="STM32" />
                                <img
                                    src="/images/logo-arduino.png"
                                    alt="Arduino Logo"
                                    title="Arduino"
                                />
                            </div>
                        </div>
                        <div className="skills-category">
                            <h3>Operating Systems</h3>
                            <div className="operating-systems">
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Tux.svg/1200px-Tux.svg.png"
                                    alt="Linux logo"
                                    title="Linux"
                                />
                                <img
                                    src="/images/logo-windows.png"
                                    alt="Windows logo"
                                    title="Windows"
                                />
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/1667px-Apple_logo_black.svg.png"
                                    alt="macOS logo"
                                    title="macOS"
                                />
                            </div>
                        </div>
                        <div className="skills-category">
                            <h3>AI Tools</h3>
                            <div className="ai-tools">
                                <img
                                    src="/images/logo-chatgpt.png"
                                    alt="ChatGPT logo"
                                    title="ChatGPT"
                                />
                                <img
                                    src="/images/logo-copilot.png"
                                    alt="GitHub Copilot logo"
                                    title="GitHub Copilot"
                                />
                                <img src="/images/logo-codeium.jpg" alt="Codeium" title="Codeium" />
                            </div>
                        </div>
                    </section>
                </section>
                {/* //  PROJECTS  // */}
                <section className="resume-section">
                    {/* Start content edits below this line. */}
                    <h2>Projects</h2>
                    <h5>
                        Incomplete list of some of my projects that I thought further
                        explanation and photos would benefit!
                    </h5>
                    {/* To add more items, copy this whole <section></section> block*/}
                    <div className="project-wrapper">
                        <section className="project-item float-below">
                            <h3>E2E Encryption in Satellite Messaging</h3>
                            <h5>2024</h5>
                            <p>
                                Techincal lead in the full stack development of end-to-end
                                encryption over satellite messaging. Leveraging the FIPS Bouncy
                                Castle library to create a hybrid ECC scheme for encryption and
                                authorization. The UI/UX below is simple because its SUPPOSED to be!
                                The complex and clever parts happen all in the background. Having
                                control of every aspect from the keys database schema, the APIs
                                between our servers and the satellite base station, and the user
                                interface (the app), is crucial in my ability to make this a
                                fantastic and secure user experience. This is very much still in
                                progress, but it has been interesting working in a space that relies
                                solely on manual satellite transmits for pushing and pulling public
                                keys between the phone and our servers.
                            </p>
                            <h4>Using Android View System</h4>
                            <div className="image-row three-images">
                                <img src="/images/e2e-project1.png" alt="phone screen shot 1" />
                                <img src="/images/e2e-project2.png" alt="phone screen shot 2" />
                                <img src="/images/e2e-project3.png" alt="phone screen shot 3" />
                            </div>
                            <h4>Using Jetpack Compose</h4>
                            <div className="image-row three-images">
                                <img
                                    src="/images/project-e2e-compose1.png"
                                    alt="compose screen shot 1"
                                />
                                <img
                                    src="/images/project-e2e-compose2.png"
                                    alt="compose screen shot 2"
                                />
                                <img
                                    src="/images/project-e2e-compose3.png"
                                    alt="compose screen shot 3"
                                />
                            </div>
                        </section>
                        <section className="project-item float-below">
                            <h3>ATAK Plugin Development</h3>
                            <h5>2023-2024</h5>
                            <div className="text-photo">
                                <p>
                                    Developed a smaller version of the Higher Ground messaging app to
                                    run inside the Android Team Awareness Kit{" "}
                                    <a href="https://tak.gov/products" className="inline-article">
                                        (ATAK)
                                    </a>
                                    . This allowed ATAK users to interface with Higher Ground's
                                    proprietary handheld device for satellite transmission of any kind
                                    of data that would normally be transmitted to the TAK servers over
                                    the internet.
                                </p>
                                <img src="/images/atak/atak-eagle-eye-shot.jpg" alt="device shot" />
                            </div>
                            <div className="image-row two-images">
                                <img
                                    src="/images/atak/atak-home-screen.png"
                                    alt="ATAK plugin screen"
                                />
                                <img
                                    src="/images/atak/atak-message-list.png"
                                    alt="ATAK message list"
                                />
                            </div>
                            <div className="image-row two-images">
                                <img
                                    src="/images/atak/atak-message-options.png"
                                    alt="ATAK Message Options"
                                />
                                <img
                                    src="/images/atak/atak-conversation-page.png"
                                    alt="ATAK conversation page"
                                />
                            </div>
                            <div className="image-row two-images">
                                <img
                                    src="/images/atak/atak-external-device-info.png"
                                    alt="ATAK External Device"
                                />
                                <img
                                    src="/images/atak/atak-pointing-screen.png"
                                    alt="ATAK Pointing Screen"
                                />
                            </div>
                        </section>
                        <section className="project-item float-below">
                            <h3>Photo Message Compression Workflow</h3>
                            <h5>2023</h5>
                            <p>
                                Developed a photo message compression workflow for the Higher Ground
                                messaging app. This workflow allows users to take a photo, crop it,
                                and compress it to a size that can be transmitted over the satellite
                                network. While minimizing latency in the app UX and time over the
                                satellite, it was important to be transparent of how long a message
                                is expected to send over the satellite based on how much a user
                                compresses it.
                            </p>
                            <div className="image-row four-images">
                                <img
                                    src="/images/image-workflow/image-processing1.png"
                                    alt="select attachment"
                                />
                                <img
                                    src="/images/image-workflow/image-processing2.png"
                                    alt="crop image"
                                />
                                <img
                                    src="/images/image-workflow/image-processing3-compression.png"
                                    alt="compress image"
                                />
                                <img
                                    src="/images/image-workflow/image-processing4-convo.png"
                                    alt="conversation"
                                />
                            </div>
                        </section>
                    </div>
                </section>
                <section className="project-item float-below">
                    <h3>Admin Site and Simplified Database Updates</h3>
                    <h5>2022 - 2023</h5>
                    <p>
                        (More details to come) This was an XL full stack project that required a
                        full development and deployment of a django admin site, database
                        redesign, and API integration, and Android App development to create a
                        HUGE improvement in how we can distribute satellite configuration
                        databases to users operating in areas where they may or may not have
                        functioning internet access. A comically simplified overview is below.
                    </p>
                    <div className="image-row one-image">
                        <img style={{background:'white'}} src="/images/django-qr-project.png" alt="admin site 1" />
                    </div>
                </section>
                <section className="project-item float-right">
                    <img src="/images/photo-imu-w-lora-small.jpg" alt="Transmitter" />
                    <h3>Draw Bridge Sensor</h3>
                    <h5>2023</h5>
                    <p>
                        Though a simple project, it is one I hold dear as it was one I came up
                        with while growing up in a small seaside town with a natorious
                        drawbridge. It uses a Feather 32u4 with LoRa radio and an IMU to
                        measure, calculate, and transmit pitch readings to a receiver. The idea
                        was for the low power transmitter to be attached to the draw bridge and
                        transmit to a receiver display at a crucial turnoff for drivers in a
                        rush to get down town.{" "}
                    </p>
                    {/* link to project */}
                    <a
                        className="btn"
                        href="https://github.com/JeffreyBradley772/bridge-tender"
                        target="_blank"
                    >
                        Code
                    </a>
                    {/* additional links, e.g. newsletter */}
                    <a
                        className="btn"
                        href="https://en.wikipedia.org/wiki/Old_Roosevelt_Bridge"
                        target="_blank"
                    >
                        Natorious Bridge
                    </a>
                </section>
                {/* End content edits here. */}
                <section className="resume-section">
                    <h2>Some Certifications</h2>
                    <ul>
                        <li>JavaScript Essential Training</li>
                        <li>React.js Essential Training</li>
                        <li>Node.js Essential Training</li>
                        <li>Databases and SQL for Data Science</li>
                        <li>Software Development in Java</li>
                        <li>Data Structures and Algorithms</li>
                        <li>Machine and Operating System Architecture</li>
                    </ul>
                </section>
                <section className="resume-section">
                    <h2>Some Strengths</h2>
                    <ul>
                        <li>
                            Tooling – I love to find the latest and best technology to optimize
                            workflows.
                        </li>
                        <li>Collaboration – I am a people person!</li>
                        <li>
                            Adaptability – I have worked primarily in startup environments and am
                            used to wearing many hats.
                        </li>
                    </ul>
                </section>
                {/* //  EDUCATION & CERTIFICATIONS  // */}
                <section className="resume-section">
                    <section className="education-item">
                        <h2>Education</h2>
                        <p>
                            <a href="https://www.wlu.edu/"> Washington and Lee University</a> BS
                            in Engineering Science &amp; Computer Science
                        </p>
                        <p>
                            Studied lots of electrical engineering and computer science with a
                            focus on machine learning and deep learning.
                        </p>
                    </section>
                </section>
                <section className="resume-section">
                    <h2>Passions</h2>
                    <p>Learning, hiking, skiing, videography/photography</p>
                </section>
                {/* End content edits here. */}
            </main>
            {/* //  FOOTER INFO, SOCIAL MEDIA  // */}
            <footer>
                {/* Start content edits below this line. */}
                <p>
                    © 2023 by Jeffrey Bradley |{" "}
                    <a href="mailto:jeffreybradley772@gmail.com">
                        jeffreybradley772@gmail.com
                    </a>
                </p>
                {/* Add or remove any networks. */}
                <ul className="socials">
                    <li>
                        <a href="https://www.linkedin.com/in/jeffreybradley772/">
                            <img
                                src="/images/icon-logo-linkedin.png"
                                target="_blank"
                                alt="My LinkedIn Profile"
                            />
                        </a>
                    </li>
                    <li>
                        <a href="https://github.com/JeffreyBradley772" target="_blank">
                            <img src="/images/icon-logo-github.png" alt="My Github Profile" />
                        </a>
                    </li>
                </ul>
                {/* End content edits here. */}
            </footer>
        </div>

    );
};

const OldResume = () => {
    return (
        <div>
            <HtmlRenderer />
        </div>
    );
};

export default OldResume;